import { Add } from 'components/common/icons/Add'
import { AngleLeft } from 'components/common/icons/AngleLeft'
import { AngleRight } from 'components/common/icons/AngleRight'
import { ArrowDropdown } from 'components/common/icons/ArrowDropdown'
import { ArrowRight } from 'components/common/icons/ArrowRight'
import { ArrowUnsorted } from 'components/common/icons/ArrowUnsorted'
import { Bookmark } from 'components/common/icons/Bookmark'
import { BookmarkHollow } from 'components/common/icons/BookmarkHollow'
import { Cancel } from 'components/common/icons/Cancel'
import { Check } from 'components/common/icons/Check'
import { CheckCircle } from 'components/common/icons/CheckCircle'
import { ChevronDown } from 'components/common/icons/ChevronDown'
import { ChevronLeft } from 'components/common/icons/ChevronLeft'
import { ChevronRight } from 'components/common/icons/ChevronRight'
import { ChevronUp } from 'components/common/icons/ChevronUp'
import { Close } from 'components/common/icons/Close'
import { ClosedCaption } from 'components/common/icons/ClosedCaption'
import { Dehaze } from 'components/common/icons/Dehaze'
import { ErrorFilled } from 'components/common/icons/ErrorFilled'
import { FacebookIcon } from 'components/common/icons/FacebookIcon'
import { Favorite } from 'components/common/icons/Favorite'
import { FileAlt } from 'components/common/icons/FileAlt'
import { Filter } from 'components/common/icons/Filter'
import { FormatListBulleted } from 'components/common/icons/FormatListBulleted'
import { Forward10 } from 'components/common/icons/Forward10'
import { Forward30 } from 'components/common/icons/Forward30'
import { Fullscreen } from 'components/common/icons/Fullscreen'
import { FullscreenExit } from 'components/common/icons/FullscreenExit'
import { HelpFilled } from 'components/common/icons/HelpFilled'
import { HighlightOff } from 'components/common/icons/HighlightOff'
import { Home } from 'components/common/icons/Home'
import { Hootie } from 'components/common/icons/Hootie'
import { InfoFilled } from 'components/common/icons/infoFilled'
import { InfoOutline } from 'components/common/icons/InfoOutline'
import { InstagramIcon } from 'components/common/icons/InstagramIcon'
import { KeyboardArrowLeft } from 'components/common/icons/KeyboardArrowLeft'
import { KeyboardArrowRight } from 'components/common/icons/KeyboardArrowRight'
import { KeyboardBackspace } from 'components/common/icons/KeyboardBackspace'
import { List } from 'components/common/icons/List'
import { LongArrowsUpAndDown } from 'components/common/icons/LongArrowsUpAndDown'
import { More } from 'components/common/icons/More'
import { Pause } from 'components/common/icons/Pause'
import { Pencil } from 'components/common/icons/Pencil'
import { PlayArrow } from 'components/common/icons/PlayArrow'
import { PauseCircleOutline } from 'components/common/icons/PauseCircleOutline'
import { PlayCircleOutline } from 'components/common/icons/PlayCircleOutline'
import { Previous10 } from 'components/common/icons/Previous10'
import { Remove } from 'components/common/icons/Remove'
import { Repeat } from 'components/common/icons/Repeat'
import { RepeatOne } from 'components/common/icons/RepeatOne'
import { Replay30 } from 'components/common/icons/Replay30'
import { Search } from 'components/common/icons/Search'
import { Settings } from 'components/common/icons/Settings'
import { Shuffle } from 'components/common/icons/Shuffle'
import { SkipNext } from 'components/common/icons/SkipNext'
import { SkipPrevious } from 'components/common/icons/SkipPrevious'
import { Snooze } from 'components/common/icons/Snooze'
import { Speed } from 'components/common/icons/Speed'
import { Star, StarState } from 'components/common/icons/Star'
import { Thumb } from 'components/common/icons/Thumb'
import { TwitterIcon } from 'components/common/icons/TwitterIcon'
import { ViewModule } from 'components/common/icons/ViewModule'
import { VisibilityOff } from 'components/common/icons/VisibilityOff'
import { VisibilityOn } from 'components/common/icons/VisibilityOn'
import { Volume } from 'components/common/icons/Volume'
import { YoutubeIcon } from 'components/common/icons/YoutubeIcon'
import { ZoomIn } from 'components/common/icons/ZoomIn'
import { ZoomOut } from 'components/common/icons/ZoomOut'

export enum IconWeight {
  light = 'LIGHT',
  regular = 'REGULAR',
  heavy = 'HEAVY',
}

export interface DefaultIconProps {
  className?: string
  onClick?: () => void
  size?: number
  title?: string
  weight?: string
}

export const defaultIconProps: DefaultIconProps = {
  className: '',
  onClick: () => {},
  size: 22,
  title: undefined,
  weight: IconWeight.light,
}

export {
  Add,
  AngleLeft,
  AngleRight,
  ArrowDropdown,
  ArrowRight,
  ArrowUnsorted,
  Bookmark,
  BookmarkHollow,
  Cancel,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Close,
  ClosedCaption,
  Dehaze,
  ErrorFilled,
  FacebookIcon,
  Favorite,
  FileAlt,
  Filter,
  Forward10,
  Fullscreen,
  FullscreenExit,
  FormatListBulleted,
  Forward30,
  HelpFilled,
  HighlightOff,
  Home,
  Hootie,
  InfoFilled,
  InfoOutline,
  InstagramIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardBackspace,
  List,
  LongArrowsUpAndDown,
  More,
  Pause,
  Pencil,
  PlayArrow,
  PauseCircleOutline,
  PlayCircleOutline,
  Previous10,
  Remove,
  Repeat,
  RepeatOne,
  Replay30,
  Search,
  Settings,
  Shuffle,
  SkipNext,
  SkipPrevious,
  Snooze,
  Speed,
  Star,
  StarState,
  Thumb,
  TwitterIcon,
  ViewModule,
  VisibilityOff,
  VisibilityOn,
  Volume,
  YoutubeIcon,
  ZoomIn,
  ZoomOut,
}
