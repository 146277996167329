import { useQuery } from '@apollo/client'
import { graphql } from 'graphql/hoopla/generated'
import { client } from 'graphql/client'

const GetFetchTitleDetailQueryDocument = graphql(/* gql_hoopla */ `
  query GetFetchTitleDetailQuery(
    $id: ID!
    $includeDeleted: Boolean
    $showHolds: Boolean = true
    $showMarketingText: Boolean = false
  ) {
    title(criteria: { id: $id, includeDeleted: $includeDeleted }) {
      abridged
      actors {
        id
        name
      }
      artKey
      authors {
        name
        id
      }
      bingePassType
      bisacs {
        id
        ancestors {
          id
          name
        }
        children {
          id
          name
          parent {
            id
            name
          }
        }
        name
        isParent
        parent {
          id
          name
        }
      }
      bundledContent {
        ...BundledContentFragment
      }
      captions {
        id
        cc
      }
      chapters {
        chapter
        duration
        ordinal
        start
        title
      }
      childrens
      circulation {
        borrowedDate
        dueDate
        id
        isRenewable
        licenseType
        maxDue
        patron {
          id
        }
        title {
          title
        }
      }
      copyright
      demo
      directors {
        name
        id
      }
      episodes {
        artKey
        circulation {
          borrowedDate
          dueDate
          id
          isRenewable
          licenseType
          patron {
            id
          }
        }
        episode
        id
        lastBorrowed
        lendingMessage
        licenseType
        mediaKey
        playbackPosition {
          percentComplete
          lastPlayed
        }
        seconds
        status
        synopsis
        subtitle
        title
      }
      externalCatalogUrl
      favorite
      fixedLayout
      genres {
        ancestors {
          id
          name
        }
        id
        name
      }
      hold @include(if: $showHolds) {
        ...HoldFragment
      }
      holdsPerCopy @include(if: $showHolds)
      id
      illustrators {
        id
        name
      }
      isbn
      issueNumberDescription
      kind {
        name
        id
        singular
        plural
      }
      language {
        name
        label
        id
      }
      lastBorrowed
      lendingMessage
      licenseType
      manga
      marketingText @include(if: $showMarketingText)
      mediaKey
      mediaType
      overlay {
        ...TitleOverlayFragment
      }
      pages
      parentalAdvisory
      patronRating {
        stars
      }
      people {
        id
        name
        relationship
      }
      percentComplete
      playbackPosition {
        percentComplete
        lastPlayed
      }
      primaryArtist {
        name
        id
        similarArtists {
          id
          name
        }
      }
      producers {
        name
        id
      }
      profanity
      publisher {
        id
        name
      }
      rating
      readAlong
      readers {
        name
        id
      }
      relatedTitles {
        ...TitleListItemFragment
      }
      releaseDate
      reviews {
        id
        source
        quote
      }
      seconds
      seriesNumberLabel
      series {
        id
        name
      }
      status
      subtitle
      subtitleLanguage {
        name
      }
      synopsis
      tags {
        id
        name
      }
      title
      titleRating {
        totalCount
        weightedAverage
      }
      tracks {
        ...TrackFragment
      }
      traditionalManga
      writers {
        id
        name
      }
      year
      zombieHoldCount @include(if: $showHolds)
      __typename
    }
  }
`)

const GetFetchBorrowInfoQueryDocument = graphql(/* gql_hoopla */ `
  query GetFetchBorrowInfoQuery($id: ID!) {
    title(criteria: { id: $id }) {
      id
      circulation {
        borrowedDate
        dueDate
        id
        isRenewable
        licenseType
        patron {
          id
        }
        title {
          title
        }
        maxDue
      }
      episodes {
        circulation {
          borrowedDate
          dueDate
          id
          isRenewable
          licenseType
          patron {
            id
          }
        }
        id
        lendingMessage
        licenseType
        status
      }
      hold {
        ...HoldFragment
      }
      holdsPerCopy
      lastBorrowed
      lendingMessage
      licenseType
      status
      zombieHoldCount
      __typename
    }
  }
`)

export function useTitleDetail(
  id?: string,
  {
    includeDeleted = false,
    fetchPolicy = '',
    nextFetchPolicy = '',
    showHolds = true,
    showMarketingText = process.env.REACT_APP_FETCH_MARKETING_TEXT === 'true',
    onCompleted,
    ...config
  }: {
    includeDeleted?: boolean
    showHolds?: boolean
    showMarketingText?: boolean
    fetchPolicy?: string
    nextFetchPolicy?: string
    onCompleted?: (title?: Title) => void
    config?: any
  } = {},
) {
  const { loading, data, error, refetch } = useQuery<{
    title: Title
  }>(GetFetchTitleDetailQueryDocument, {
    variables: {
      id,
      includeDeleted,
      fetchPolicy,
      nextFetchPolicy,
      showHolds,
      showMarketingText,
    },
    onCompleted: (data) => {
      onCompleted && onCompleted(data?.title)
    },
    errorPolicy: 'all',
    ...config,
  })

  return { loading, title: data?.title, error, refetch }
}

export function fetchTitleDetail(id: string) {
  return client.query<{ title: Title }>({
    query: GetFetchTitleDetailQueryDocument,
    variables: { id },
    errorPolicy: 'all',
  })
}

export function fetchTitleFromCache(id: string) {
  return client.readQuery<{ title: Title }>({
    query: GetFetchTitleDetailQueryDocument,
    variables: { id },
  })
}

export function fetchBorrowInfo(id: string) {
  return client.query<{ title: Title }>({
    query: GetFetchBorrowInfoQueryDocument,
    variables: { id },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })
}
