import classNames from 'classnames'
import { useRef } from 'react'
import { useMount, useRafState, useUnmount } from 'react-use'
import { useReducedMotion } from '../../hooks/useReducedMotion'

interface IconProps {
  height: number
  styles: string
  width: number
}

const Icon = ({ styles, width, height }: IconProps) => (
  <svg
    className={styles}
    data-testid="loading-spinner"
    style={{ width, height }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32">
    <path transform="translate(2)" d="M0 12 V20 H4 V12z">
      <animate
        attributeName="d"
        values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
        dur="1.2s"
        repeatCount="indefinite"
        begin="0"
        keyTimes="0;.2;.5;1"
        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
        calcMode="spline"
      />
    </path>
    <path transform="translate(8)" d="M0 12 V20 H4 V12z">
      <animate
        attributeName="d"
        values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
        dur="1.2s"
        repeatCount="indefinite"
        begin="0.2"
        keyTimes="0;.2;.5;1"
        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
        calcMode="spline"
      />
    </path>
    <path transform="translate(14)" d="M0 12 V20 H4 V12z">
      <animate
        attributeName="d"
        values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
        dur="1.2s"
        repeatCount="indefinite"
        begin="0.4"
        keyTimes="0;.2;.5;1"
        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
        calcMode="spline"
      />
    </path>
    <path transform="translate(20)" d="M0 12 V20 H4 V12z">
      <animate
        attributeName="d"
        values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
        dur="1.2s"
        repeatCount="indefinite"
        begin="0.6"
        keyTimes="0;.2;.5;1"
        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
        calcMode="spline"
      />
    </path>
    <path transform="translate(26)" d="M0 12 V20 H4 V12z">
      <animate
        attributeName="d"
        values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
        dur="1.2s"
        repeatCount="indefinite"
        begin="0.8"
        keyTimes="0;.2;.5;1"
        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
        calcMode="spline"
      />
    </path>
  </svg>
)

interface LoadingProps {
  delay?: number
  height?: number
  primary?: boolean
  secondary?: boolean
  width?: number
}

export default function Loading({
  delay = 0,
  width = 64,
  height = 64,
  primary = false,
  secondary = false,
}: LoadingProps) {
  const [isDelayed, setDelay] = useRafState(false)

  const timerId = useRef<ReturnType<typeof setTimeout>>()

  const prefersReducedMotion = useReducedMotion()

  useMount(() => {
    if (delay > 0) {
      setDelay(true)
      timerId.current = setTimeout(() => {
        setDelay(false)
      }, delay)
    }
  })

  useUnmount(() => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
  })

  if (isDelayed) return null

  const determineColor = primary || (!primary && !secondary)
  const styles = classNames('text-white fill-current', {
    '!text-hoopla-blue': determineColor,
  })

  return (
    <div className="flex w-full content-center items-center justify-center">
      {!prefersReducedMotion && (
        <Icon styles={styles} width={width} height={height} />
      )}
      {prefersReducedMotion && <p className="m-0 p-0">Loading ...</p>}
    </div>
  )
}
