import { KindName } from 'types/hoopla'
import create, { SetState, State } from 'zustand'

interface CurrentlyPlayingState extends State {
  currentlyPlayingTitle?: string // titleId
  currentlyPlayingKind?: KindName
  currentEpisode?: number

  clearCurrentlyPlayingTitle: () => void
  setCurrentlyPlayingTitle: (
    currentlyPlayingTitle: string,
    currentlyPlayingKind: KindName,
    currentEpisode?: number,
  ) => void
}

export const useCurrentlyPlaying = create<CurrentlyPlayingState>(
  (set: SetState<CurrentlyPlayingState>) => ({
    currentlyPlayingTitle: undefined,
    currentlyPlayingKind: undefined,
    currentEpisode: undefined,

    clearCurrentlyPlayingTitle: () =>
      set({
        currentlyPlayingTitle: undefined,
        currentlyPlayingKind: undefined,
      }),
    setCurrentlyPlayingTitle: (
      currentlyPlayingTitle,
      currentlyPlayingKind,
      currentEpisode,
    ) => set({ currentlyPlayingTitle, currentlyPlayingKind, currentEpisode }),
  }),
)
