import { AvailabilityFilter, DateRange } from 'types/hoopla'

import type { KindName } from 'types/hoopla'
import type { LibraryExt, PatronExt } from 'types/hoopla'

export type DateFormatItem = 'numeric' | '2-digit'
export type DateFormat = {
  month: DateFormatItem
  day: DateFormatItem
  year: DateFormatItem
}

const isAvail = (value: any): value is AvailabilityFilter =>
  Object.values(AvailabilityFilter).includes(value)

export const isAvailability = (
  availabilityParam: AvailabilityFilter | undefined,
) => isAvail(availabilityParam)

export enum DateLabel {
  SevenDays = DateRange.Last_7Days,
  ThirtyDays = DateRange.Last_30Days,
  ThreeMonths = DateRange.Last_3Months,
  SixMonths = DateRange.Last_6Months,
  TwelveMonths = DateRange.Last_12Months,
}

export enum HoldStatus {
  Approved = 'APPROVED',
  Borrowed = 'BORROWED',
  Denied = 'DENIED',
  Reserved = 'RESERVED',
  Requested = 'REQUESTED',
  Snoozed = 'SNOOZED',
  Suspended = 'SUSPENDED',
  Waiting = 'WAITING',
  None = 'NONE',
}

export type Badge = {
  href?: string
  src: string
  target: string
  type: string
}

export type RegistrationFormData = {
  coords?: GeolocationCoordinates
  library?: LibraryExt
  libraryCard?: string
  libraryPin?: string
  firstName?: string
  lastName?: string
  provisional?: boolean
  nonUsAgreement?: boolean
  email?: string
  password?: string
  emailConsent?: boolean
  notificationConsent?: boolean
  registrationConfirmation?: {
    borrowMessage?: string
    kindMessage?: string
    patron: PatronExt
    supportMessage?: string
    token: string
  }
}

export interface BaseRegistrationProps {
  onBack?: (data?: RegistrationFormData) => void
  onNext?: (data?: RegistrationFormData) => void
}

export type TitleRequest = {
  id: string
  artKey: string
  artistName: string
  contentId: number
  holdEmailsAllowed: boolean
  inserted: number | string
  issueNumberDescription: string
  kindName: KindName
  libraryId: number
  libraryName: string
  patronId: number
  position: number
  sendStandardDeclineMessage: boolean
  status: HoldStatus
  title: string
  titleId: number
  updated: string | number
}

export type User = {
  id: string
  authToken: string
  authTokenExpires: number
  email: string
  enabled: boolean
  hideHistory: boolean
  inserted: number
  patrons: PatronExt[]
}
