// REACT IMPORTS
import { FormattedMessage } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'

// ABSOLUTE IMPORTS
import classNames from 'classnames'
import BadgeListItem from 'components/badges/BadgeListItem'
import appleV2 from 'components/badges/store-badges/v2/app-store.svg'
import amazonV2 from 'components/badges/store-badges/v2/amazon-app-store.png'
import googleV2 from 'components/badges/store-badges/v2/google-play.svg'
import useIsAUNZ from 'components/hooks/useIsAUNZ'
import { format } from 'date-fns'
import HooplaLogo from 'images/Hoopla_white.png'
import { useAutopilot } from 'state/useAutoPilot'
import { useKinds } from 'state/useKinds'
import { useUser } from 'state/useUser'

import { Badge } from 'types/common'

export const footerBadges: Badge[] = [
  {
    src: appleV2,
    href: 'https://itunes.apple.com/us/app/hoopla-digital/id580643740?mt=8&amp;uo=4',
    target: 'itunes_store',
    type: 'APP',
  },
  {
    src: amazonV2,
    href: 'https://www.amazon.com/Hoopla-Digital/dp/B00JMRGQHI/ref=sr_1_1?ie=UTF8&amp;qid=1413582359&amp;sr=8-1&amp;keywords=hoopla+digital',
    target: 'amazon_play_store',
    type: 'APP',
  },
  {
    src: googleV2,
    href: 'https://play.google.com/store/apps/details?id=com.hoopladigital.android',
    target: 'google_play_store',
    type: 'app',
  },
]

function Footer() {
  // HOOKS
  const navigate = useNavigate()

  // STATE
  const isAUNZ = useIsAUNZ()
  const enabledKinds = useKinds((state) => state.enabledKinds)
  const showAutoPilot = useAutopilot((state) => state.showAutoPilot)
  const user = useUser((state) => state.user)

  const columnStyles = 'flex flex-col mt-4 items-center sm:items-start'
  const columnHeaderStyles = 'font-bold mt-4 mb-2'
  const linkStyles = 'text-white mb-2 break-words hover:text-gray-300'

  return (
    <footer className="w-full bg-hoopla-blue px-8 pb-3 text-white">
      <div className="mx-auto max-w-7xl">
        <div className="mb-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 bg:grid-cols-6 bg:flex-row">
          <div className={columnStyles}>
            <img
              alt="hoopla"
              className="w-32 cursor-pointer bg:w-44"
              onClick={() => navigate('/')}
              src={HooplaLogo}
            />
            <Link className={classNames(linkStyles, 'sm:pl-4 bg:pl-8')} to="/">
              <FormattedMessage
                id="footer.for.patrons"
                defaultMessage="For Patrons"
              />
            </Link>
            <a
              className={classNames(linkStyles, 'sm:pl-4 bg:pl-8')}
              href="https://library.hoopladigital.com"
              target="_blank"
              rel="noreferrer">
              <FormattedMessage
                id="footer.for.libraries"
                defaultMessage="For Libraries"
              />
            </a>
            <a
              className={classNames(linkStyles, 'sm:pl-4 bg:pl-8')}
              href="https://vendor.hoopladigital.com/"
              target="_blank"
              rel="noreferrer">
              <FormattedMessage
                id="footer.for.vendors"
                defaultMessage="For Vendors"
              />
            </a>
            <Link
              className={classNames(linkStyles, 'mt-2 sm:pl-4 bg:pl-8')}
              to={`/feedback`}>
              <FormattedMessage
                id="footer.contact"
                defaultMessage="Contact Us"
              />
            </Link>
          </div>
          <div className={columnStyles}>
            <h3 className={columnHeaderStyles}>
              <FormattedMessage
                id="footer.our.company"
                defaultMessage="Our Company"
              />
            </h3>
            <Link className={linkStyles} to="/about">
              <FormattedMessage
                id="footer.our.story"
                defaultMessage="Our Story"
              />
            </Link>
            <a
              className={linkStyles}
              href="https://library.hoopladigital.com"
              target="_blank"
              rel="noreferrer">
              <FormattedMessage
                id="footer.for.library"
                defaultMessage="Get hoopla for your Library"
              />
            </a>
            <a
              className={linkStyles}
              href="https://hoopladigital.com/contentproviders"
              target="_blank"
              rel="noreferrer">
              <FormattedMessage
                id="footer.your.content"
                defaultMessage="Get your content on hoopla"
              />
            </a>
            <a
              className={linkStyles}
              href="https://careers.midwesttape.com"
              target="_blank"
              rel="noreferrer">
              <FormattedMessage
                id="footer.join.team"
                defaultMessage="Join our team"
              />
            </a>
          </div>

          <div className={columnStyles}>
            <h3 className={columnHeaderStyles}>
              <FormattedMessage
                id="footer.our.content"
                defaultMessage="Our Content"
              />
            </h3>
            {enabledKinds.map((kind) => (
              <Link
                className={linkStyles}
                key={kind.id}
                to={`/browse/${kind?.slug}`}>
                {kind.plural}
              </Link>
            ))}
          </div>

          <div className={columnStyles}>
            <h3 className={columnHeaderStyles}>
              <FormattedMessage
                id="footer.our.communities"
                defaultMessage="Our Communities"
              />
            </h3>
            {!isAUNZ && (
              <a
                className={linkStyles}
                href="https://www.hoopladigital.com/collection/70690"
                target="_blank"
                rel="noreferrer">
                <FormattedMessage
                  id="footer.content.book.club"
                  defaultMessage="hoopla book club"
                />
              </a>
            )}
            <Link className={linkStyles} to="/faq">
              <FormattedMessage
                id="footer.patron.support"
                defaultMessage="Patron Support - FAQs"
              />
            </Link>
          </div>

          <div className={columnStyles}>
            <h3 className={columnHeaderStyles}>Our Social</h3>
            <a
              className={linkStyles}
              href="https://www.facebook.com/hoopladigital"
              target="_blank"
              rel="noreferrer">
              Facebook
            </a>
            <a
              className={linkStyles}
              href="https://twitter.com/hooplaDigital"
              target="_blank"
              rel="noreferrer">
              Twitter
            </a>
            <a
              className={linkStyles}
              href="https://www.instagram.com/hoopladigital"
              target="_blank"
              rel="noreferrer">
              Instagram
            </a>
            <a
              className={linkStyles}
              href="https://www.youtube.com/user/hoopladigital"
              target="_blank"
              rel="noreferrer">
              YouTube
            </a>
            <a
              className={linkStyles}
              href="https://www.tiktok.com/@hoopla.digital"
              target="_blank"
              rel="noreferrer">
              TikTok
            </a>
            <a
              className={linkStyles}
              href="https://www.linkedin.com/company/midwesttape/"
              target="_blank"
              rel="noreferrer">
              LinkedIn
            </a>
          </div>

          <div className={columnStyles}>
            <h4 className={columnHeaderStyles}>
              <FormattedMessage
                id="footer.hoopla.available"
                defaultMessage="hoopla is available on"
              />
            </h4>
            <div className="grid grid-cols-1 gap-2">
              {footerBadges.map((badge: Badge, index: number) => (
                <BadgeListItem
                  key={index}
                  badge={badge}
                  className="m-auto w-40 sm:m-0"
                />
              ))}
            </div>
          </div>
        </div>

        <div className="text-sm">
          <span>&copy;{format(new Date(), 'yyyy')}. All rights reserved. </span>
          <Link className="text-white hover:text-gray-300" to="/privacy">
            <FormattedMessage
              id="footer.link.privacy"
              defaultMessage="Privacy Policy"
            />
          </Link>
          <span> | </span>
          <Link className="text-white hover:text-gray-300" to="/terms">
            <FormattedMessage id="footer.link.terms" defaultMessage="Terms" />
          </Link>
        </div>
        {/* THIS IS WHERE AUTOPILOT LINKS GET GENERATED */}
        {!user && showAutoPilot && <div className="be-ix-link-block" />}
      </div>
    </footer>
  )
}

export default Footer
