import isEqual from 'lodash/isEqual'
import keyBy from 'lodash/keyBy'
import create, { GetState, SetState, State } from 'zustand'
import { KindName } from 'types/hoopla'
import { graphql } from 'graphql/hoopla/generated'
import { client } from '../graphql/client'

import type { KindExt } from 'types/hoopla'

export type KindResponse = KindExt | null | undefined

interface KindState extends State {
  enabledKinds: KindExt[]
  fetch: () => void
  getKindById: (kindId: string) => KindResponse
  getKindByName: (kindName: KindName) => KindResponse
  getKindsEnabledByName: () => { [kindName: string]: KindExt }
  getKindsByName: () => { [kindName: string]: KindExt }
  orderedKinds: KindExt[]
  refetch: () => void
  save: (enabledKinds: KindExt[], orderedKinds: KindExt[]) => void
}

const GetKindsQueryDocument = graphql(/* gql_hoopla */ `
  query GetKindsQuery {
    kinds {
      enabled
      id
      name
      plural
      singular
    }
  }
`)

// this is the order kinds should be displayed when shown in
// lists or groups (per MWT)
export const ORDERED_KINDS = [
  KindName.Audiobook,
  KindName.Comic,
  KindName.Ebook,
  KindName.Movie,
  KindName.Music,
  KindName.Television,
  KindName.BingePass,
]

export function getSlugForKind(kind: KindExt): string {
  if (kind.name === KindName.BingePass) {
    return 'binge'
  } else {
    return kind.name.toLowerCase()
  }
}

export const useKinds = create(
  (set: SetState<KindState>, get: GetState<KindState>): KindState => ({
    enabledKinds: [],
    fetch: async () => {
      const { data } = await client.query({
        query: GetKindsQueryDocument,
        fetchPolicy: 'cache-first',
      })

      const kinds = data?.kinds as KindExt[]

      if (kinds) {
        const orderedKinds = ORDERED_KINDS.map((kindName: string) =>
          kinds.find((kind: KindExt) => kind.name === kindName),
        )
          .filter((kind) => !!kind)
          .map((k) => ({ ...k, slug: getSlugForKind(k as KindExt) }))

        const enabledKinds = orderedKinds.filter((kind) => kind.enabled)

        get().save(enabledKinds as KindExt[], orderedKinds as KindExt[])
      }
    },
    getKindById: (kindId: string) => {
      const orderedKinds = get().orderedKinds

      if (!orderedKinds || !orderedKinds.length) return null

      return orderedKinds.find(
        (kind: KindExt) => parseInt(kind.id, 10) === parseInt(kindId, 10),
      )
    },
    getKindByName: (kindName: string) => {
      const orderedKinds = get().orderedKinds

      if (!orderedKinds || !orderedKinds.length) return null

      return orderedKinds.find((kind: KindExt) => kind.name === kindName)
    },
    getKindsEnabledByName: () => {
      const enabledKinds = get().enabledKinds

      return keyBy(enabledKinds, (kind) => kind.name.toLowerCase() as string)
    },
    getKindsByName: () => {
      const orderedKinds = get().orderedKinds

      return keyBy(orderedKinds, (kind) => kind.name.toLowerCase() as string)
    },
    orderedKinds: [],
    refetch: () => {
      return get().fetch()
    },
    save: (enabledKinds: KindExt[], orderedKinds: KindExt[]) => {
      if (
        !isEqual(get().enabledKinds, enabledKinds) &&
        !isEqual(get().orderedKinds, orderedKinds)
      ) {
        set({ enabledKinds, orderedKinds })
      }
    },
  }),
)
